/* Scripts for package.json */
// "start": "react-scripts --max_old_space_size=4096 start",
/* "build": "react-scripts --max_old_space_size=4096 build",
    
    change to redeploy the app after upgrading to heroku-22
    
    */

/* import custom font 'inter' as per Figma file */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    height: -webkit-fill-available;
    width: 100%;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    width: 100%;
}
/*#root {
    border: red solid 1px;
}*/

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.map-container {
    height: 100%;
}
.mapboxgl-popup-content {
    width: 24rem;
    transform: scale(0.65);
    background-color: rgba(255, 255, 255, 0);
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    margin-right:-15%;
}

.mapbox-custom-marker-pointer{
    cursor: pointer;
}

#root {
    /* border: red solid 1px; */
    height: 100%;
    width: 100%;
}

@supports (-webkit-touch-callout: none) {
    .h-screen {
        height: -webkit-fill-available;
    }
}
@media screen and (min-width: 768px) {
    html,
    body,
    #root {
        display: flex;
    }
    .mapboxgl-popup-content {
        width: 24rem;
        transform: scale(0.8);
        background-color: rgba(255, 255, 255, 0);
    }
    .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
        margin-right:-10%;
    }
}
@media screen and (min-width: 1024px) {
    html,
    body,
    #root {
        display: block;
    }
}
