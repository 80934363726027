@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-primary {
        @apply max-w-fit rounded-lg bg-[#3E4784] py-2.5 px-4 text-xs font-normal text-white hover:font-semibold active:bg-[#132286] active:ring-0 focus:outline-none focus:ring focus:ring-blue-500 cursor-pointer;
    }
    .btn-primary-disabled {
        @apply max-w-fit rounded-lg bg-slate-500 py-2.5 px-4 text-xs font-normal text-white cursor-default;
    }
    .btn-secondary {
        @apply max-w-fit rounded-lg border border-solid border-slate-300 bg-white py-2.5 px-4 text-xs font-normal text-gray-700 hover:font-semibold hover:border-slate-400 active:border-2 active:ring-0 focus:outline-none focus:ring-2 focus:ring-slate-800 cursor-pointer;
    }
    .btn-secondary-disabled {
        @apply max-w-fit rounded-lg border border-dashed border-slate-300 bg-white py-2.5 px-4 text-xs font-normal text-gray-400 cursor-default;
    }
    .btn-opaque {
        @apply max-w-fit rounded-lg bg-[#00000080] py-1.5 px-4 text-xs font-normal text-white hover:font-semibold active:bg-[#132286] active:ring-0 focus:outline-none focus:ring focus:ring-blue-500 cursor-pointer
    }
    .btn-opaque-md {
        @apply rounded-lg bg-[#00000080] py-2 px-4 text-center text-sm font-medium text-white hover:font-bold active:bg-[#132286] active:ring-0 focus:outline-none focus:ring focus:ring-blue-500 cursor-pointer;
    }
    .btn-primary-md {
        @apply rounded-lg border border-solid border-gray-300 bg-[#3E4784] py-2.5 px-4 text-center text-sm font-medium text-white hover:font-bold active:bg-[#132286] active:ring-0 focus:outline-none focus:ring focus:ring-blue-500 cursor-pointer;
    }
    .btn-primary-md-disabled {
        @apply rounded-lg border border-solid border-gray-300 bg-slate-500 py-2.5 px-4 text-center text-sm font-medium text-white cursor-default;
    }
    .btn-secondary-md {
        @apply rounded-lg border border-solid border-gray-300 bg-white py-2.5 px-4 text-center text-sm font-medium text-gray-700 hover:font-bold hover:border-gray-400 active:border-2 active:ring-0 focus:outline-none focus:ring-2 focus:ring-slate-800 cursor-pointer;
    }
    .btn-opaque-lg {
        @apply rounded-lg bg-[#00000080] py-2.5 px-5 text-center text-base font-medium text-white hover:font-bold active:bg-[#132286] active:ring-0 focus:outline-none focus:ring focus:ring-blue-500 cursor-pointer;
    }
    .btn-primary-lg {
        @apply rounded-lg border border-solid border-gray-300 bg-[#3E4784] py-3 px-5 text-center text-base font-medium text-white hover:font-bold active:bg-[#132286] active:ring-0 focus:outline-none focus:ring focus:ring-blue-500 cursor-pointer;
    }
    .btn-secondary-lg {
        @apply rounded-lg border border-solid border-gray-300 bg-white py-3 px-5 text-center text-base font-medium text-gray-700 hover:font-bold hover:border-gray-400 active:border-2 active:ring-0 focus:outline-none focus:ring-2 focus:ring-slate-800 cursor-pointer;
    }
    .red {
        @apply border-red-500 border
    }
    .blue {
        @apply border-blue-500 border
    }
    .green {
        @apply border-green-500 border
    }
    .black {
        @apply border-black border
    }
    .capitalize-first::first-letter {
        @apply uppercase;
    }
}

*,
body {
    box-sizing: border-box;
}
@supports (-webkit-touch-callout: none) {
    .h-screen {
        height: -webkit-fill-available;
    }
}

